window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    $.effects = require('jquery-ui/ui/effect')

    require('bootstrap');
    require('overlayscrollbars');
    require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');
    require('../../vendor/almasaeed2010/adminlte/plugins/select2/js/select2');
    window.moment = require('moment')
    window.datepicker = require('bootstrap-datepicker')
    window.bootstrapSwitch = require('bootstrap-switch')
    window.colorpicker = require('bootstrap-colorpicker')
    window.slider = require('bootstrap-slider')
    window.summernote = require('summernote')
    window.toastr = require('toastr')

    $('.slider').slider();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('#color, .color').colorpicker();
    $('.color').on('colorpickerChange', function(event) {
        $(this).find('.fa-square').css('color', event.color.toString());
    });

    $("input[data-bootstrap-switch]").each(function(){
        $(this).bootstrapSwitch('state', $(this).prop('checked'));
    });
} catch (e) {}
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
